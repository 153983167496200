export const emptyMqaInterval = {
  audioReceive: [],
  audioTransmit: [],
  intervalMetadata: {
    peerReflexiveIP: '0.0.0.0',
    peripherals: [],
    processAverageCPU: 0,
    processMaximumCPU: 0,
    systemAverageCPU: 0,
    systemMaximumCPU: 0,
  },
  networkType: '',
  intervalNumber: 0,
  videoReceive: [],
  videoTransmit: [],
};

export const emptyAudioReceive = {
  common: {
    common: {
      direction: 'inactive',
      isMain: true,
      mariFecEnabled: false,
      mariQosEnabled: false,
      multistreamEnabled: false,
    },
    dtlsBitrate: 0,
    dtlsPackets: 0,
    fecBitrate: 0,
    fecPackets: 0,
    maxBitrate: 0,
    mediaHopByHopLost: 0,
    rtcpBitrate: 0,
    rtcpPackets: 0,
    rtpBitrate: 0,
    rtpHopByHopLost: 0,
    rtpPackets: 0,
    rtpRecovered: 0,
    rtxBitrate: 0,
    rtxPackets: 0,
    srtcpUnprotectErrors: 0,
    srtpUnprotectErrors: 0,
    stunBitrate: 0,
    stunPackets: 0,
    transportType: 'UDP',
  },
  streams: [],
};

export const emptyAudioReceiveStream = {
  common: {
    codec: 'opus',
    concealedFrames: 0,
    csi: [],
    maxConcealRunLength: 0,
    optimalBitrate: 0,
    optimalFrameRate: 0,
    receivedBitrate: 0,
    receivedFrameRate: 0,
    renderedFrameRate: 0,
    requestedBitrate: 0,
    requestedFrameRate: 0,
    rtpEndToEndLost: 0,
    maxRtpJitter: 0,
    meanRtpJitter: 0,
    rtpPackets: 0,
    ssci: 0,
  },
};

export const emptyAudioTransmit = {
  common: {
    availableBitrate: 0,
    common: {
      direction: 'inactive',
      isMain: true,
      mariFecEnabled: false,
      mariQosEnabled: false,
      multistreamEnabled: false,
    },
    dtlsBitrate: 0,
    dtlsPackets: 0,
    fecBitrate: 0,
    fecPackets: 0,
    maxBitrate: 0,
    queueDelay: 0,
    remoteJitter: 0,
    remoteLossRate: 0,
    remoteReceiveRate: 0,
    roundTripTime: 0,
    rtcpBitrate: 0,
    rtcpPackets: 0,
    rtpBitrate: 0,
    rtpPackets: 0,
    rtxBitrate: 0,
    rtxPackets: 0,
    stunBitrate: 0,
    stunPackets: 0,
    transportType: 'UDP',
  },
  streams: [],
};

export const emptyAudioTransmitStream = {
  common: {
    codec: 'opus',
    csi: [],
    requestedBitrate: 0,
    requestedFrames: 0,
    rtpPackets: 0,
    ssci: 0,
    transmittedBitrate: 0,
    transmittedFrameRate: 0,
  },
};

export const emptyVideoReceive = {
  common: {
    common: {
      direction: 'inactive',
      isMain: true, // Not avaliable
      mariFecEnabled: true, // Not avaliable
      mariQosEnabled: true, // Not avaliable
      multistreamEnabled: true, // Not avaliable
    },
    dtlsBitrate: 0, // Not avaliable
    dtlsPackets: 0, // Not avaliable
    fecBitrate: 0, // Not avaliable
    fecPackets: 0, // Not avaliable
    maxBitrate: 0,
    mediaHopByHopLost: 0,
    rtcpBitrate: 0, // Not avaliable
    rtcpPackets: 0, // Not avaliable
    rtpBitrate: 0,
    rtpHopByHopLost: 0,
    rtpPackets: 0,
    rtpRecovered: 0, // Not avaliable
    rtxBitrate: 0, // Not avaliable
    rtxPackets: 0, // Not avaliable
    srtcpUnprotectErrors: 0, // Not avaliable
    srtpUnprotectErrors: 0, // Not avaliable
    stunBitrate: 0, // Not avaliable
    stunPackets: 0, // Not avaliable
    transportType: 'UDP',
  },
  streams: [],
};

export const emptyVideoReceiveStream = {
  common: {
    codec: 'H264',
    concealedFrames: 0, // Not avaliable
    csi: [],
    maxConcealRunLength: 0, // Not avaliable
    optimalBitrate: 0,
    optimalFrameRate: 0,
    receivedBitrate: 0,
    receivedFrameRate: 0,
    renderedFrameRate: 0, // Not avaliable
    requestedBitrate: 0,
    requestedFrameRate: 0,
    rtpEndToEndLost: 0,
    rtpJitter: 0,
    rtpPackets: 0,
    ssci: 0, // Not avaliable
  },
  h264CodecProfile: 'BP',
  isActiveSpeaker: true,
  optimalFrameSize: 0, // Not avaliable
  receivedFrameSize: 0,
  receivedHeight: 0,
  receivedKeyFrames: 0,
  receivedKeyFramesForRequest: 0,
  receivedKeyFramesSourceChange: 0,
  receivedKeyFramesUnknown: 0,
  receivedWidth: 0,
  requestedFrameSize: 0,
  requestedKeyFrames: 0,
};

export const emptyVideoTransmit = {
  common: {
    availableBitrate: 0, // Not avaliable currently hardcoded
    common: {
      direction: 'inactive',
      isMain: true,
      mariFecEnabled: false, // Not avaliable
      mariQosEnabled: false, // Not avaliable
      multistreamEnabled: false, // Not avaliable
    },
    dtlsBitrate: 0, // Not avaliable
    dtlsPackets: 0, // Not avaliable
    fecBitrate: 0, // Not avaliable
    fecPackets: 0, // TODO: check inbound-rtp// Not avaliable
    maxBitrate: 0, // Currently hardcoded
    queueDelay: 0, // outboundRtp.totalPacketSentDelay  // TODO: check if totalInterFrameDelay/ packetSentDelay/ jitterBufferDalay
    remoteJitter: 0, // remoteInboundRtp.jitter
    remoteLossRate: 0, // comparedResults.lossRate
    remoteReceiveRate: 0, // compareResults.packetsLost
    roundTripTime: 0, // compareResults.roundTripTime
    rtcpBitrate: 0, // Dont have access to it
    rtcpPackets: 0, // Dont have access to rtcp
    rtpBitrate: 0, // Dont have access
    rtpPackets: 0, // outboundRtp.packetsSent
    rtxBitrate: 0, // Dont have access to it
    rtxPackets: 0, // Dont have access to it
    stunBitrate: 0, // Dont have access to it
    stunPackets: 0, // Dont have access to it
    transportType: 'UDP', // TODO: need to calculate
  },
  streams: [],
};

export const emptyVideoTransmitStream = {
  common: {
    codec: 'H264',
    csi: [],
    duplicateSsci: 0, // Not Avaliable
    requestedBitrate: 0, // TODO: from remote SDP
    requestedFrames: 0, // TODO: from remote SDP
    rtpPackets: 0, // same as rtp packets
    ssci: 0,
    transmittedBitrate: 0, // TODO: get in the candidate pair
    transmittedFrameRate: 0, // TODO: from track info
  },
  h264CodecProfile: 'BP', // TODO: from localSDP
  isAvatar: false, // Not Avaliable
  isHardwareEncoded: false, // Not Avaliable
  localConfigurationChanges: 2, // Not Avaliable
  maxFrameQp: 0, // Not Avaliable
  maxNoiseLevel: 0, // Not Avaliable
  minRegionQp: 0, // Not Avaliable
  remoteConfigurationChanges: 0, // Not Avaliable
  requestedFrameSize: 0, // TODO: from remote SDP
  requestedKeyFrames: 0, // outbound Fir request
  transmittedFrameSize: 0, // Not Avaliable
  transmittedHeight: 0,
  transmittedKeyFrames: 0, // Key frames encoded
  transmittedKeyFramesClient: 0, // Not Avaliable
  transmittedKeyFramesConfigurationChange: 0, // Not Avaliable
  transmittedKeyFramesFeedback: 0, // Not Avaliable
  transmittedKeyFramesLocalDrop: 0, // Not Avaliable
  transmittedKeyFramesOtherLayer: 0, // Not Avaliable
  transmittedKeyFramesPeriodic: 0, // Not Avaliable
  transmittedKeyFramesSceneChange: 0, // Not Avaliable
  transmittedKeyFramesStartup: 0, // Not Avaliable
  transmittedKeyFramesUnknown: 0, // Not Avaliable
  transmittedWidth: 0,
};
